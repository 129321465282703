import React, { Dispatch } from 'react';
import Table from 'antd/es/table';
import { ColumnType } from 'antd/es/table/interface';
import { AnyObject } from 'antd/es/_util/type';
import { TablePaginationConfig } from 'antd'; // Import TablePaginationConfig type

type TableProps = {
    columns: ColumnType<AnyObject>[];
    data: any;
    checkboxEnabled?: boolean; 
    checkedUsers?: number[];
    setCheckedUsers?: Dispatch<number[]>;
    currentPagination?: number;
    setCurrentPagination?: Dispatch<number>
    triggerFetch?: boolean;
    setTriggerFetch?: Dispatch<boolean>;
    maxLogs?: number;
};

function TableC(props: TableProps): JSX.Element {
    const { columns, data, checkboxEnabled = true, checkedUsers, setCheckedUsers, currentPagination, setCurrentPagination, triggerFetch, setTriggerFetch, maxLogs } = props;

    // Trigger fetchData when the page changes
    const handleTableChange = (pagination: TablePaginationConfig) => {
        if (pagination.current !== undefined) {
            const currentPage = pagination.current ?? 1;
            const newOffset = (currentPage) * 10;
            setCurrentPagination && setCurrentPagination(newOffset);
            setTriggerFetch && setTriggerFetch(!triggerFetch)
        }
    };

    return (
        <Table
            columns={columns}
            style={{ marginTop: 20, marginRight: 20, marginLeft: 20 }}
            dataSource={data}
            onChange={handleTableChange}
            rowSelection={
                checkboxEnabled
                    ? {
                          selectedRowKeys: checkedUsers,
                          onChange: (keys) => {
                            setCheckedUsers && setCheckedUsers(keys as number[]);
                          },
                          getCheckboxProps: (record) => ({
                              disabled: record.disabled,
                          }),
                      }
                    : undefined // If checkboxEnabled is false, omit rowSelection
            }
            pagination={{
                pageSize: 10,
                hideOnSinglePage: true,
                current: currentPagination && Math.floor(currentPagination / 10), // Derive current page accurately
                total: maxLogs
            }}
        />
    );
}

export default TableC;
