import { fetchErrorAction, fetchSuccessAction } from "./creators/common_actions";
import { AppDispatch } from "../store";
import { post } from "../../services/axios_wrapper";

const api_path = "/api/users/"

export const logout = () => (dispatch: AppDispatch): Promise<boolean> => {
    const request: any = post(`${api_path}logout`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}
