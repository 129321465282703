import React, {CSSProperties} from 'react';

type PageContentProps = {
    className?: string;
    children: NonNullable<React.ReactNode>,
    style?: CSSProperties
}

function PageContent(props: PageContentProps): JSX.Element {
    const {className, style, children, ...rest} = props;

    return (
        <div style= {style? style:{height: '100%'}} className={className} {...rest}>
            {children}
        </div>
    )
}

export default PageContent;
