import {PageResource} from "../../models/dtos/page_resource";

export const createEmptyPage = <T>(limit?: number): PageResource<T> => {
    return {
        content: [],
        first: true,
        last: true,
        number: 0,
        numberOfElements: 0,
        limit: limit? limit: 10,
        totalElements: 0,
        totalPages: 0
    }
}
