import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import WelcomePage from '../views/welcome_page';
import LoginPage from '../views/login/login';
import LogsPage from '../views/logs/logs';
import ManagePage from '../views/manage/manage';
import { useUserContext } from '../contexts/userContext';
import LogLogsPage from '../views/logs/occurrence_logs';
import GroupLogsPage from '../views/logs/group_logs';

interface ProtectedRouteProps {
    element: React.ReactElement;
    requiredUserId?: number;  // Optional prop to check for user ID (e.g., for /manage route)
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, requiredUserId }) => {
    const { userToken, loggedInUser } = useUserContext();  // Assuming loggedInUser contains the user's ID

    // If the user is not logged in, redirect to login
    if (!userToken) {
        return <Navigate to="/login" replace />;
    }

    // If a specific user ID is required and the logged-in user doesn't match, redirect to 403 error page
    if (requiredUserId && loggedInUser.role === requiredUserId) {
        return <Navigate to="/errors/403" replace />;
    }

    // If user passes authentication and authorization checks, render the requested element
    return element;
};

export const getRoutes = createBrowserRouter([
    {
        path: `/login`,
        element: <LoginPage />,
        errorElement: <span>Error</span>,
    },
    {
        path: "/errors",
        children: [
            { path: '401', element: <h1>401 Error</h1> },
            { path: '403', element: <h1>403 Error</h1> },
            { path: '404', element: <h1>404 Error</h1> },
            { path: '500', element: <h1>500 Error</h1> },
            { path: '*', element: <h1>Generic Error</h1> }
        ]
    },
    {
        path: '/',
        element: <Navigate to="/logs" replace />, // Redirects to /login
    },
    {
        path: `/logs`,
        element: <ProtectedRoute element={<LogsPage />} />,
        errorElement: <h1>Error</h1>,
        children: [
            {
                path: `:group_id`,
                element: <ProtectedRoute element={<GroupLogsPage />} />,
                errorElement: <h1>Error</h1>,
            },
            {
                path: `:group_id/:log_id`,
                element: <ProtectedRoute element={<LogLogsPage />} />,
                errorElement: <h1>Error</h1>,
            },
        ],
    },
    {
        path: `/manage`,
        element: <ProtectedRoute element={<ManagePage />} requiredUserId={3}/>,
        errorElement: <h1>Error</h1>,
    }
]);
