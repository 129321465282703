import axios from "axios";
import { fetchErrorAction, fetchSuccessAction } from "./creators/common_actions";
import { AppDispatch } from "../store";
import { User } from "../../models/entities/user";

const api_path = "/api/auth/"

export const login = (email:string, password:string) => (dispatch: AppDispatch): Promise<{
    user: User,
    token: string
}> => {
    const request: any = axios.get(`${api_path}login?email=${email}&password=${password.replace('#', "%23")}`)

    return Promise.resolve(request)
    
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason.response.data.error)); // Pass the error message to the catch block
            return Promise.reject(reason.response.data.error); // Reject the promise with the error message
        });
}
