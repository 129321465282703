import { AnyAction, applyMiddleware, createStore, Store } from 'redux';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';
import rootReducer, { RootState } from '../reducers';

export default function configureStore(preloadedState = {}): Store<RootState> {
    // Directly apply middleware without devtools
    const middlewareEnhancer = applyMiddleware(thunkMiddleware);

    return createStore(rootReducer, preloadedState, middlewareEnhancer);
}

export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;
