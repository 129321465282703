import React, { useEffect, useState } from 'react';
import Page from '../../components/page_structure/page';
import PageHeader from '../../components/page_structure/page_header';
import PageContent from '../../components/page_structure/page_content';
import { useParams } from 'react-router-dom';
import { Log } from '../../models/entities/log';
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { Card, Typography, Badge, Divider, Tag, Row, Col, Space } from 'antd';
import { UserOutlined, ClockCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { logDetails } from '../../redux/actions/log_actions';

const { Title, Text } = Typography;

function LogLogsPage(): JSX.Element {
    const { log_id } = useParams<{ log_id: string }>();

    const dispatch: AppDispatch = useDispatch();

    // Define log state with the correct type
    const [log, setLog] = useState<Log | null>(null);

    useEffect(() => {
        if (log_id) {
            const fetchData = async () => {
                try {
                    const log = await dispatch(logDetails(log_id));
                    setLog(log.log);
                } catch (error) {
                    console.error(error);
                }
            };
            fetchData();
        }
    }, [dispatch]);

    // If log data is not available, show loading or empty state
    if (!log) {
        return (
            <Page title="Logs">
                <PageHeader title={`Testing Panda - ${log_id} Occurrence Logs`} extraButton={true}/>
                <PageContent>Not available logs...</PageContent>
            </Page>
        );
    }

    const riskLevels: { [key: number]: { label: string, color: string } } = {
        1: { label: 'Critical (1)', color: 'purple' },
        2: { label: 'Very High (2)', color: 'volcano' },
        3: { label: 'High (3)', color: 'red' },
        4: { label: 'Medium (4)', color: 'orange' },
        5: { label: 'Low (5)', color: 'green' }};

    const risk = riskLevels[log.risk] || { label: 'Unknown', color: 'gray' };

    const reversedStatuses: { [key: string]: { color: string } } = {
        'Open': { color: '#f5222d' },
        'In Progress': { color: '#faad14' },
        'Resolved': { color: '#52c41a' }
    };    

    return (
        <Page title="Logs">
            <PageHeader title={`Testing Panda - ${log_id} Occurrence Logs`} />
            <PageContent>
                <Card
                    title={<Title level={3}>{log.title}</Title>}
                    bordered={false}
                    style={{
                        width: "100%",
                        borderRadius: 8,
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                        marginBottom: 20,
                    }}
                >
                    {/* Status Badge */}
                    <Row gutter={16}>
                        <Col span={12}>
                        <Badge
                            count={log.status}
                            style={{
                                backgroundColor: reversedStatuses[log.status]?.color || '#d9d9d9', // Default color if not found
                            }}
                            overflowCount={10}
                        />
                        </Col>
                        <Col span={12}>
                        <Text strong>Risk Level: </Text>
                            <Tag color={risk.color}>
                                {risk.label}
                            </Tag>
                        </Col>
                    </Row>
                    <Divider />

                    {/* Server and Service Information */}
                    <Row gutter={16}>
                        <Col span={12}>
                            <Text strong>Server:</Text> {log.server}
                        </Col>
                        <Col span={12}>
                            <Text strong>Service:</Text> {log.service}
                        </Col>
                    </Row>

                    {/* Description */}
                    <Divider />
                    <Row gutter={16}>
                        <Col span={24}>
                            <Text strong>Description:</Text> {log.description}
                        </Col>
                    </Row>

                    {/* Error Code */}
                    <Row gutter={16} style={{ marginTop: 10 }}>
                        <Col span={24}>
                            <Space>
                                <ExclamationCircleOutlined style={{ color: '#f5222d' }} />
                                <Text strong>Error Code:</Text> <Text type="danger">{log.error}</Text>
                            </Space>
                        </Col>
                    </Row>

                    {/* Assignee and Time Created */}
                    <Divider />
                    <Row gutter={16}>
                        <Col span={24}>
                            <Text strong>Request:</Text> {log.request}
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={16}>
                        <Col span={12}>
                            <Space>
                                <UserOutlined />
                                <Text strong>Assigned to:</Text> {log.assignee}
                            </Space>
                        </Col>
                        <Col span={12}>
                            <Space>
                                <ClockCircleOutlined />
                                <Text strong>Created at:</Text> {new Date(log.time_created).toLocaleString()}
                            </Space>
                        </Col>
                    </Row>

                    {/* Risk Level */}
                    <Divider />
                    <Row gutter={16}>
                        <Col span={24}>
                            <Text strong>Group ID:</Text> {log.group_id}
                        </Col>
                    </Row>
                </Card>
            </PageContent>
        </Page>
    );
}

export default LogLogsPage;
